<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="sidebar-right"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      right
      backdrop
      shadow
      no-header
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            Tambah Tukar Dinas
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <b-form
          class="p-2"
          @submit.prevent="SaveShiftEx()"
        >
          <b-form-group
            label="Tanggal Shift"
            label-for="shift_date"
          >
            <flat-pickr
              id="shift_date"
              v-model="shift_date"
              class="form-control"
              placeholder="Pilih Tanggal"
              @input="getEmployeeReff()"
            />
          </b-form-group>
          <b-form-group
            label="Rekan Kerja"
            label-for="employee_code_target"
          >
            <v-select
              id="employee_code_target"
              v-model="employee_code_target"
              placeholder="Pilih"
              label="employee_full_name"
              :options="EmployeeReff"
              class="mb-2"
            />
          </b-form-group>
          <b-form-group
            label="Alasan Tukar Dinas"
            label-for="alasan"
          >
            <b-form-textarea
              id="alasan"
              v-model="description"
              rows="3"
              placeholder="Ketik Alasan"
              class="mb-2"
            />
          </b-form-group>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              block
            >
              <b-spinner
                v-show="isSpinner"
                class="ml-auto"
                :hidden="!isSpinner"
                small
                type="grow"
              />
              <div :hidden="isSpinner">
                <span class="align-middle">Simpan</span>
              </div>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="resetForm()"
            >
              Batal
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
    <b-modal
      ref="v-modal"
      ok-variant="danger"
      modal-class="modal-danger"
      hide-footer
      centered
      title="Peringatan !"
    >
      <!-- <b-card-text v-if="isFormRequired">
        Tanggal Mulai, Tanggal Akhir & Jenis Voucher Harus di isi !!!
      </b-card-text>
      <b-card-text v-if="isValid">
        {{ message }}
      </b-card-text> -->
      <b-card-text>
        {{ message }}
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BButton,
  BSidebar,
  BSpinner,
  VBToggle,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    BRow,
    BCol,
    BCardText,
    BButton,
    BSidebar,
    BSpinner,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormDatepicker,
    BFormTextarea,
    ToastificationContent,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      // isFormRequired: false,
      // isValid: false,
      isSpinner: false,
      message: null,
      shift_date: '',
      description: null,
      employee_code_target: [],
      EmployeeReff: [],
      employeeData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  mounted() {
    //this.getEmployeeReff()
    this.countLiveDate()
  },
  methods: {
    resetForm() {
      this.$refs['sidebar-right'].hide()
      this.shift_date = null
      this.employee_code_target = null
      this.property = null
    },
    getEmployeeReff() {
      axios.post('/shiftExchange/employeeShiftReff', {
        employee_code: this.employeeData.employee_code, 
        shift_date: this.shift_date   
      }).then(response => {
        console.log(response)
        this.EmployeeReff = response.data.data.available_officers
      })
    },
    SaveShiftEx() {
      //console.log(this.employee_code_target);
      this.isSpinner = true
      axios.post('/shiftExchange/create', {
        employee_code: this.employeeData.employee_code,
        employee_code_target: this.employee_code_target.employee_code,
        shift_date: this.shift_date,
        description: this.description,
      })
        .then(response => {
          // if (response.data.data.length === 0) {
          //   console.log(response)
          //   this.$refs['v-modal'].show()
          //   this.isValid = true
          //   this.message = response.data.message
          //   // console.log(this.message);
          // } else {
            console.log(response)
            this.$swal({
              icon: 'success',
              title: 'Data berhasil disimpan',
              timer: 1500,
              showCancelButton: false,
              showConfirmButton: false,
            }).then(
              () => {},
              // handling the promise rejection
              dismiss => {
                if (dismiss === 'timer') {
                  // console.log('I was closed by the timer')
                }
              },
            )
          // }
        })
        .catch(error => {
          // console.log(error);
          if (error.response.status === 400) {
            console.log(error.response)
            this.$refs['v-modal'].show()
            this.message = error.response.data.message
          } 
          if (error.response.status === 401) {
          this.$router.push({ name: "not-authorized" }).catch(() => {});
          } 
          else {
            console.log(error)
          }
        })
        .finally(() => {
          this.isSpinner = false,
          this.$refs['sidebar-right'].hide()
          this.$parent.getshiftExsEmployee()
        })
    },
    countLiveDate() {
      if (this.leave_date_to === ''){
        this.CountLive = '0'
        //console.log( '0 hari');
      } else {
        var day3 = new Date(this.leave_date_start);
        var day4 = new Date(this.leave_date_to);

        var DIT1 = day4.getTime() - day3.getTime();
        var DID1 = DIT1 / (1000 * 3600 * 24);

        this.CountLive = DID1 + 1
        //console.log(day3 + ',' + this.leave_date_to + ',' + this.CountLive);
      }
    },
  },
}
</script>
 <style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
