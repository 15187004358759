<template>
  <div>
    <!-- float button add -->
    <div class="btn-scroll-to-top">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-b-toggle.sidebar-right
        variant="primary"
        size="lg"
        class="btn-icon rounded-circle"
      >
        <feather-icon icon="PlusIcon" size="16" />
      </b-button>
    </div>
    <b-alert v-height-fade show dismissible fade class="mb-2" variant="primary">
      <div class="alert-body">
        <span>Klik tombol + untuk melakukan pengajuan tukar dinas !</span>
      </div>
    </b-alert>
    <b-card title="Pencarian Data Tukar Dinas">
      <b-row>
        <b-col md="12">
          <b-row>
            <b-col md="2" sm="12">
              <b-form-group>
                <v-select
                  v-model="statusReff"
                  :disabled="isSpinner"
                  placeholder="Pilih Status"
                  label="statusName"
                  :options="status"
                />
              </b-form-group>
            </b-col>
            <b-col md="2" sm="12">
              <b-form-group>
                <v-select
                  v-model="month_periode"
                  :disabled="isSpinner"
                  placeholder="Pilih Bulan"
                  label="monthName"
                  :options="monthPeriode"
                />
              </b-form-group>
            </b-col>
            <b-col md="2" sm="12">
              <b-form-group>
                <v-select
                  v-model="year_periode"
                  :disabled="isSpinner"
                  placeholder="Pilih Tahun"
                  label="year"
                  :options="yearPeriode"
                />
              </b-form-group>
            </b-col>
            <b-col md="3" sm="12">
              <b-form-group>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  block
                  @click="searchLeaveEmployee()"
                >
                  <b-spinner
                    v-show="isSpinner"
                    class="ml-auto"
                    :hidden="!isSpinner"
                    small
                    type="grow"
                  />
                  <div :hidden="isSpinner">
                    <feather-icon icon="SearchIcon" class="mr-50" />
                    <span class="align-middle">Search</span>
                  </div>
                </b-button>
              </b-form-group>
            </b-col>
            <b-col md="3" sm="12">
              <b-form-group>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  block
                  @click="resetFilter()"
                >
                  Reset
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <!-- pricing free trial -->
    <div class="pricing-free-trial" v-if="isNull">
      <b-row>
        <b-col lg="10" offset-lg="3" class="mx-auto">
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-3">
              <h3 class="text-primary">Data tukar dinas tidak ditemukan!</h3>
              <h5>Klik tombol + untuk melakukan pengajuan tukar dinas.</h5>
            </div>

            <!-- images -->
            <b-img
              fluid
              :src="
                require('@/assets/images/illustration/pricing-Illustration.svg')
              "
              class="pricing-trial-img"
              alt="svg img"
            />
            <!--/ images -->
          </div>
        </b-col>
      </b-row>
    </div>
    <!--/ pricing free trial -->

    <b-overlay
      :show="isOverlay"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-row v-if="isTableShow">
        <b-col md="4" sm="12" v-for="shiftEx in shiftExs" :key="shiftEx.code">
          <b-card no-body>
            <b-card-header class="align-items-baseline">
              <div>
                <b-card-title class="mb-1">{{ shiftEx.code }}</b-card-title>
                <b-card-sub-title class="mb-0 font-italic">
                Dibuat pada {{ timeAgo(shiftEx.created_at) }}
                </b-card-sub-title>
              </div>

              <b-dropdown
                variant="link"
                no-caret
                right
                class="chart-dropdown"
                toggle-class="p-0"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="18"
                    class="text-body cursor-pointer"
                  />
                </template>
                <b-dropdown-item 
                href="#" 
                @click="onClickUpdate(shiftEx)"
                :disabled="shiftEx.status_text !== 'OPEN'"
                >
                  Ubah Data
                </b-dropdown-item>
                <b-dropdown-item 
                href="#" 
                @click="confirmText(shiftEx)"
                :disabled="shiftEx.status_text !== 'OPEN'"
                > 
                  Batal 
                </b-dropdown-item>
              </b-dropdown>


            </b-card-header>

            <b-card-body>
              <b-card-text v-if="shiftEx.emp_full_name != null">
                  Mengajukan tukar dinas pada tanggal <strong>{{ stdDate(shiftEx.schedule_shift_date) }}</strong> dari shift {{ shiftEx.schedule_shift_description }} 
                  ke {{ shiftEx.schedule_shift_description_target }}, dengan <strong> {{ shiftEx.emp_full_name_target }} </strong> untuk kerperluan 
                  {{ shiftEx.description}}
              </b-card-text>
              <b-card-text v-else>
                  Data Tidak Ditemukan
              </b-card-text>
              <b-badge
                class="mb-1"
                :variant="status_kode[1][shiftEx.status_code]"
              >
                {{ status_kode[0][shiftEx.status_code] }}
              </b-badge>
              <b-card-text class="font-italic" v-if="shiftEx.updated_at != null">
                Diperiksa pada {{ longDate(shiftEx.updated_at) }}
              </b-card-text>
              <b-card-text class="font-italic" v-else>
                Belum diperiksa
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>

    <!-- cuti sidebar -->
    <add-shift-ex-sidebar />
    <!-- update modal -->
    <b-modal
      ref="UpdateshiftEx"
      title="Ubah Data Cuti"
      ok-title="Simpan"
      cancel-variant="outline-secondary"
      hide-footer
      centered
    >
        <b-form
          class="p-2"
          @submit.prevent="SaveEditExShift()"
        >
          <b-form-group
            label="Tanggal Shift"
            label-for="shift_date"
          >
            <flat-pickr
              id="shift_date"
              v-model="shift_date"
              class="form-control"
              placeholder="Pilih Tanggal"
              @input="getEmployeeReff()"
            />
          </b-form-group>
          <b-form-group
            label="Rekan Kerja"
            label-for="employee_code_target"
          >
            <v-select
              id="employee_code_target"
              v-model="employee_code_target"
              placeholder="Pilih"
              label="employee_full_name"
              :options="EmployeeReff"
              class="mb-2"
            />
          </b-form-group>
          <b-form-group
            label="Alasan Tukar Dinas"
            label-for="alasan"
          >
            <b-form-textarea
              id="alasan"
              v-model="description"
              rows="3"
              placeholder="Ketik Alasan Cuti"
              class="mb-2"
            />
          </b-form-group>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              block
            >
              <b-spinner
                v-show="isSpinner"
                class="ml-auto"
                :hidden="!isSpinner"
                small
                type="grow"
              />
              <div :hidden="isSpinner">
                <span class="align-middle">Simpan</span>
              </div>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="resetForm()"
            >
              Batal
            </b-button>
          </div>
        </b-form>
    </b-modal>
    <b-modal
      ref="e-modal"
      ok-variant="danger"
      modal-class="modal-danger"
      hide-footer
      centered
      title="Peringatan !"
    >
      <!-- <b-card-text v-if="isFormRequired">
        Tanggal Mulai, Tanggal Akhir & Jenis Voucher Harus di isi !!!
      </b-card-text>
      <b-card-text v-if="isValid">
        {{ message }}
      </b-card-text> -->
      <b-card-text>
        {{ message }}
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BAlert,
  BModal,
  BBadge,
  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BCardHeader,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BImg,
  BSpinner,
  BOverlay,
  BTable,
  BPagination,
  BInputGroup,
  BForm,
  BFormSelect,
  BFormInput,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  VBToggle,
  BFormTextarea,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { heightFade } from "@core/directives/animations";
import axios from "axios";
import AddShiftExSidebar from "./AddShiftExSidebar.vue";
import ModalUpdate from "./ModalUpdate.vue";
import moment from "moment";
import "moment/locale/id";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BAlert,
    BModal,
    BBadge,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BCardHeader,
    vSelect,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BImg,
    BSpinner,
    BOverlay,
    BForm,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BTable,
    BFormTextarea,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    AddShiftExSidebar,
    ModalUpdate,
    flatPickr,
  },
  directives: {
    Ripple,
    heightFade,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      isOverlay: false,
      isNull: false,
      isTableShow: false,
      isSpinner: false,
      isLoadingHideButton: true,
      isDisableByVerified: true,
      status: [],
      year_periode: [],
      month_periode: [],
      statusReff: [],
      monthPeriode: [
        {
          monthCode: "01",
          monthName: "Januari",
        },
        {
          monthCode: "02",
          monthName: "Februari",
        },
        {
          monthCode: "03",
          monthName: "Maret",
        },
        {
          monthCode: "04",
          monthName: "April",
        },
        {
          monthCode: "05",
          monthName: "Mei",
        },
        {
          monthCode: "06",
          monthName: "Juni",
        },
        {
          monthCode: "07",
          monthName: "Juli",
        },
        {
          monthCode: "08",
          monthName: "Agustus",
        },
        {
          monthCode: "09",
          monthName: "September",
        },
        {
          monthCode: "10",
          monthName: "Oktober",
        },
        {
          monthCode: "11",
          monthName: "November",
        },
        {
          monthCode: "12",
          monthName: "Desember",
        },
      ],
      yearPeriode: [
        {
          yearCode: "2022",
          year: "2022",
        },
        {
          yearCode: "2023",
          year: "2023",
        },
        {
          yearCode: "2024",
          year: "2024",
        },
        {
          yearCode: "2025",
          year: "2025",
        },
        // {
        //   yearCode: "2026",
        //   year: "2026",
        // },
        // {
        //   yearCode: "2027",
        //   year: "2027",
        // },
        // {
        //   yearCode: "2028",
        //   year: "2028",
        // },
        // {
        //   yearCode: "2029",
        //   year: "2029",
        // },
        // {
        //   yearCode: "2030",
        //   year: "2030",
        // },
      ],
      employeeData: JSON.parse(localStorage.getItem("userData")),
      shiftExs: [],
      status_kode: [
        {
          "SHF-00": "OPEN",
          "SHF-01": "PROPOSED",
          "SHF-02": "APPROVED",
          "SHF-03": "CANCELED",
          "SHF-04": "REJECTED",
          "SHF-05": "VERIFIED",
        },
        {
          "SHF-00": "light-primary",
          "SHF-01": "light-warning",
          "SHF-02": "light-info",
          "SHF-03": "light-secondary",
          "SHF-04": "light-danger",
          "SHF-05": "success",
        },
      ],
      selected: [],
      LeavesCode: [],
      ushiftEx: [],
      shift_date: null,
      description: null,
      employee_code_target: null,
      EmployeeReff: [],
      message: null,
    };
  },
  created() {
    this.getshiftExsEmployee();
    //this.getEmployeeReff()
    this.getLeavesStatusReff();
  },
  mounted(){
    this.intervalFetchData();
  },
  methods: {
    timeAgo(date) {
      moment.locale("id");

      return moment(date).fromNow();
    },
    longDate(date) {
      moment.locale("id");

      return moment(date).format("dddd, DD MMMM  YYYY h:mm a");
    },
    stdDate(date) {
      moment.locale("id");

      return moment(date).format("dddd, DD MMMM YYYY");
    },
    getEmployeeReff() {
      axios.post('/shiftExchange/employeeShiftReff', {
        employee_code: this.employeeData.employee_code, 
        shift_date: this.shift_date   
      }).then(response => {
        console.log(response)
        this.EmployeeReff = response.data.data.available_officers
      })
    },
    resetFilter() {
      this.status = [];
      this.month_periode = [];
      this.year_periode = [];
    },
    resetSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    getLeavesStatusReff() {
      axios.get("/shiftExchange/statusReff").then((response) => {
        console.log(response.data);
        this.status = response.data.data;
      });
    },
    intervalFetchData() {
      setInterval(() => {
        this.fetchDataList();
      }, 300000);
    },
    fetchDataList() {
      axios
        .post("/shiftExchange/list", {
          employee_code: this.employeeData.employee_code,
          month_periode: this.month_periode.monthCode,
          year_periode: this.year_periode.year,
          status: this.statusReff.statusKode,
        })
        .then((res) => {
          this.shiftExs = res.data.data;
        });
    },
    getshiftExsEmployee() {
      (this.isOverlay = true), (this.isLoadingHideButton = false);
      axios
        .post("/shiftExchange/list", {
          employee_code: this.employeeData.employee_code,
          month_periode: this.month_periode.monthCode,
          year_periode: this.year_periode.year,
          status: this.statusReff.statusKode,
        })
        .then((response) => {
          //this.ToastDataFound()
          console.log(response);
          this.shiftExs = response.data.data;
          this.isTableShow = true;
          this.isNull = false;
        })
        .catch((error) => {
          console.log(error.response.status);
          if (error.response.status === 400) {
            console.log(error);
          }
          if (error.response.status === 404) {
            this.isNull = true;
            this.isTableShow = false;
          }
          if (error.response.status === 401) {
            this.$router.push({ name: "not-authorized" }).catch(() => {});
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          (this.isOverlay = false), (this.isLoadingHideButton = true);
        });
    },
    searchLeaveEmployee() {
      (this.isOverlay = true), (this.isLoadingHideButton = false);
      axios
        .post("leaves/list", {
          employee_code: this.employeeData.employee_code,
          month_periode: this.month_periode.monthCode,
          year_periode: this.year_periode.year,
          status: this.statusReff.statusKode,
        })
        .then((response) => {
          this.ToastDataFound()
          console.log(response);
          this.leaves = response.data.data;
          this.isTableShow = true;
          this.isNull = false;
        })
        .catch((error) => {
          console.log(error.response.status);
          if (error.response.status === 400) {
            console.log(error);
          }
          if (error.response.status === 404) {
            this.ToastDataNotFound();
            this.isNull = true;
            this.isTableShow = false;
          }
          if (error.response.status === 401) {
            this.$router.push({ name: "not-authorized" }).catch(() => {});
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          (this.isOverlay = false), (this.isLoadingHideButton = true);
        });
    },
    onClickUpdate(shiftEx) {
      this.ushiftEx = shiftEx.code;
      console.log(this.ushiftEx);
      this.$refs["UpdateshiftEx"].show();
      if(this.ushiftEx !== null) {
        axios.post('/shiftExchange/detil', {
        shift_exchange_code: this.ushiftEx
        })
        .then(response => {
          console.log(response);
          this.shift_date = response.data.data.date
          this.description = response.data.data.description
          this.employee_code_target = response.data.data.emp_full_name_target
          // this.detil = response.data
          // this.leave_date_start = response.data.data.leaves_periode_start
        })
      } else {
        console.log(error);
      }
    },
    SaveEditExShift() {
      console.log(this.ushiftEx);
      this.isOverlay = true
      axios.patch('/shiftExchange/update', {
        employee_code: this.employeeData.employee_code,
        shift_exchange_code: this.ushiftEx,
        shift_date: this.shift_date,
        employee_code_target: this.employee_code_target.employee_code,
        description: this.description,
      })
        .then(response => {
          //this.$router.push({ name: "cuti" }).catch(() => {});
          console.log(response)
          this.$swal({
            icon: 'success',
            title: 'Data berhasil disimpan',
            timer: 1500,
            showCancelButton: false,
            showConfirmButton: false,
          }).then(
            () => {},
            // handling the promise rejection
            dismiss => {
              if (dismiss === 'timer') {
                // console.log('I was closed by the timer')
              }
            },
          )
        // }
        })
        .catch(error => {
          console.log(error.response.status);
          if (error.response.status === 400) {
            console.log(error.response.data.message)
            this.$refs['e-modal'].show()
            this.message = error.response.data.message
          } 
          if (error.response.status === 401) {
          this.$router.push({ name: "not-authorized" }).catch(() => {});
          } 
          if (error.response.status === 500) {
            this.$refs['e-modal'].show()
            this.message = 'Tukar dinas tidak bisa dilakukan karena jadwal anda libur !'
          }
          else {
            console.log(error.response)
          }
        })
        .finally(() => {
          this.isOverlay = false
          this.$refs["UpdateshiftEx"].hide();
          this.getshiftExsEmployee();
          // this.$router.push({ name: 'cuti' }).catch(() => {})
          // this.$refs['sidebar-right-update'].hide()
        })
    },
    confirmText(shiftEx) {
      this.ushiftEx = shiftEx.code;
      this.$swal({
        title: "Apa anda yakin?",
        text: "Tukar dinas yang sudah dibatalkan tidak dapat dikembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isOverlay = true
          axios
            .patch("/shiftExchange/cancle", {
              shift_exchange_code: this.ushiftEx,
            })
            .then(() => {
              this.$swal({
                icon: "success",
                title: "Canceled!",
                text: "Cuti anda berhasil dibatalkan.",
                timer: 1500,
              });
            })
            .catch((error) => {
              console.log(error.response.data.message);
            })
            .finally(() => {
              this.getshiftExsEmployee();
              this.isOverlay = true
            });
        }
      });
    },
    ToastDataNotFound() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Data tidak ditemukan.",
          icon: "BellIcon",
          variant: "danger",
        },
      });
    },
    ToastDataFound() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Data ditemukan.",
          icon: "BellIcon",
          variant: "success",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-scroll-to-top {
  position: fixed;
  bottom: 5%;
  right: 30px;
  z-index: 99;

  // opacity: 0;
  // // transform: translateY(100px);
  // transition: all .5s ease;

  // &.show {
  //   opacity: 1;
  //   // transform: translateY(0)
  // }
}
@import "@core/scss/vue/pages/page-pricing.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
